import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['purchaseLine']

  prefillQuantity() {
    this.purchaseLineTargets.forEach( line => {
      let quantityToBeReceived = line.querySelector(".quantity-to-be-received")?.innerText;
      let quantityReceivedNow = line.querySelector(".quantity-received-now");
      if (quantityReceivedNow) {
        quantityReceivedNow.value = parseFloat(quantityToBeReceived).toFixed(1);
        if (quantityReceivedNow.value > 0) { quantityReceivedNow.classList.add('glow-input-field') }
        setTimeout(() => quantityReceivedNow.classList.remove('glow-input-field'), 1000);
      }
    });
  }

}
