import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ["branch", "icon"]

  toggleBranches(e) {
    this.branchTargets.forEach(branch => branch.classList.toggle('d-none'))
    this.iconTargets.forEach(icon => icon.classList.toggle('d-none'))
  }

  expandAllBranches() {
    // unfold all branches
    document.querySelectorAll("div[data-target='materials--tree.branch']").forEach(branch => {
       branch.classList.remove('d-none');
     });

    // plus-minus icons
    document.querySelectorAll("i.fa-plus-square").forEach(icon => {
       icon.classList.add('d-none');
     });
    document.querySelectorAll("i.fa-minus-square").forEach(icon => {
       icon.classList.remove('d-none');
     });
  }
}
